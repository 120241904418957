export default {
  de: {
    defaultCountry: 'de',
    countries: ['de', 'at', 'ch', 'it'],
    path: {
      situation: '/ungeplant-schwanger/erste-hilfe',
      problems: '/abtreibung/abtreibungstest',
      resources: '/ungeplant-schwanger/was-sind-meine-staerken',
      ressources: '/ungeplant-schwanger/was-sind-meine-staerken',
    },
  },
};
